import React from "react";

import { SEO } from "../components";
import withLayout from "../hoc/withLayout/withLayout";

const ReferFriend = () => {
  return (
    <>
      <SEO title="Refer friends" />
      <div id="friendbuy-leads"></div>
    </>
  );
};

export default withLayout(ReferFriend);
